"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FasletWidget = void 0;
const react_1 = __importStar(require("react"));
function FasletWidget({ shopId, brandId, fasletTag, productId, productName, productImageUrl, locale, variants, colors, onAddToCart, shopPageUrl, onResult }) {
    // Add script tag to head
    (0, react_1.useEffect)(() => {
        var _a;
        const existing = document.querySelector('script#faslet-script-tag');
        // Don't add twice
        if (existing) {
            return;
        }
        const root = (_a = document.getElementsByTagName('script')[0]) !== null && _a !== void 0 ? _a : document.head.lastChild;
        const faslet = document.createElement('script');
        faslet.type = 'text/javascript';
        faslet.id = 'faslet-script-tag';
        faslet.src = 'https://widget.prod.faslet.net/faslet-app.min.js';
        faslet.defer = true;
        if (root) {
            root.parentNode.insertBefore(faslet, root);
        }
        else {
            document.head.appendChild(faslet);
        }
    }, []);
    window._faslet = Object.assign(Object.assign({}, window._faslet), { id: productId, variants: variants.map((variant) => ({
            size: variant.sizeLabel,
            sku: variant.sku,
            id: variant.variantId,
            available: variant.inStock,
            color: variant.colorId,
            price: variant.price,
            imageUrl: variant.imageUrl
        })), colors, shopUrl: shopPageUrl, addToCart: onAddToCart, onResult });
    return (react_1.default.createElement("faslet-app", { "shop-id": shopId, platform: "unknown", "product-name": productName, categories: fasletTag, brand: brandId, "product-img": productImageUrl, locale: locale }));
}
exports.FasletWidget = FasletWidget;
