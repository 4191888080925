"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFasletOrderTracking = void 0;
const react_1 = require("react");
const useFasletOrderTracking = (shopId, orderNumber, paymentStatus, products) => {
    const [loaded, setLoaded] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        var _a;
        const existing = document.querySelector('script#faslet-order-tracking-tag');
        // Don't add twice
        if (!existing) {
            const root = (_a = document.getElementsByTagName('script')[0]) !== null && _a !== void 0 ? _a : document.head.lastChild;
            const faslet = document.createElement('script');
            faslet.type = 'text/javascript';
            faslet.id = 'faslet-order-tracking-tag';
            faslet.src = 'https://widget.prod.faslet.net/faslet-orders.js';
            if (root) {
                root.parentNode.insertBefore(faslet, root);
            }
            else {
                document.head.appendChild(faslet);
            }
            const gtm = document.createElement('script');
            gtm.type = 'text/javascript';
            gtm.id = 'faslet-gtm-tag';
            gtm.src = 'https://www.googletagmanager.com/gtag/js?id=G-6J8TML143D';
            gtm.defer = true;
            if (root) {
                root.parentNode.insertBefore(gtm, root);
            }
            else {
                document.head.appendChild(gtm);
            }
            faslet.onload = () => {
                window._faslet_orders.configure();
                setLoaded(true);
            };
        }
        if (loaded) {
            products.forEach((product) => {
                var _a;
                (_a = window._faslet_orders) === null || _a === void 0 ? void 0 : _a.event('widget_order_track', shopId, {
                    sku: product.sku,
                    correlationId: product.productId,
                    title: product.productName,
                    variant_id: product.variantId,
                    variant: product.variantName,
                    price: product.priceTimesQuantity,
                    quantity: product.quantity,
                    order: orderNumber,
                    payment_status: paymentStatus
                });
            });
        }
    }, [shopId, orderNumber, products, loaded]);
};
exports.useFasletOrderTracking = useFasletOrderTracking;
